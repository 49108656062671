<div>
  <div class="row d-flex align-items-center justify-content-center outer-div">
    <div class="inner-div">
      <div class="title">
        THANK YOU FOR YOUR DEALERLINE ACCESS REQUEST!
      </div>
      <div class="description">
        Your request has been successfully submitted. Our team will evaluate the information and update you on the
        status via email.
      </div>
      <a class="btn redirectBtn" target="_blank" href='https://www.wheelpros.com'>VISIT WHEELPROS.COM</a>
    </div>
  </div>
</div>