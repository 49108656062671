import { checkSpecials , emailValidateRegX} from '../constants'

export const isPure = ( value )=>{
    if(checkSpecials.test(value)){
      return false
    }else{
      return true
    }
}

export const emailValidator = (email) => {
    const reg = emailValidateRegX;

    if (reg.test(email) === false) {
        return false;
    }

    return true;
};