<div>
    <div class="row d-flex align-items-center justify-content-center outer-div"  >
      <div class="inner-div">
        <div class="title">
          DEALERLINE ACCESS REQUEST
        </div>
        <div class="description">
          Please provide all information below to submit your request for access. If you have any questions, please reach out to your local sales team.

          
        </div>

        <div class="errorInfo"  >
          {{errorMsg}}
        </div>
        <!-- SIGNUP FORM STARTS -->
       
        <form #signUpForm="ngForm" class="signUpform" (ngSubmit)="onSubmit(signUpForm)">
          
          <div class="form-group">
            <input type="text" class="form-control " [ngClass]="{'is-invalid':isFnameError}" id="firstName"  aria-describedby="firstName" (keyup)="inputValidation('fname','keyup',fname.value)" placeholder="First name" ngModel name="fname" #fname>
            
  
          </div>
          <div class="form-group">
            <input type="text" class="form-control" id="lastName" [ngClass]="{'is-invalid':isLnameError}" placeholder="Last name" ngModel name="lname" #lname (keyup)="inputValidation('lname','keyup',lname.value)"> 
          </div>
        
          <div class="form-group">
            <input type="email" class="form-control" id="email" [ngClass]="{'is-invalid':isEmailError}" placeholder="Email id" ngModel name="email" #email (keyup)="inputValidation('email','keyup',email.value)">
          </div>
          <div class="form-group">
            <input type="text" class="form-control" id="businessName" [ngClass]="{'is-invalid':isBusinessNameError}" placeholder="Business Name" ngModel name="BusinesName" #BusinesName (keyup)="inputValidation('BusinesName','keyup',BusinesName.value)">
          </div>
          <div class="form-group">
            <input type="number" class="form-control" id="accountNumber" [ngClass]="{'is-invalid':isAccountNumberError}" placeholder="WheelPros Account Number" ngModel name="acNumber" #acNumber (keyup)="inputValidation('acNumber','keyup',acNumber.value)">
            <small id="accountNumberInfo" class="form-text text-muted">WheelPros Account Number can be minimum of 5 to maximum of 10 digits.</small>
          </div>
          
          <button type="submit" [disabled]="isProcessing" class="btn formSubmitBtn">Submit Application</button>
        </form>
      </div>
    </div>
  </div>