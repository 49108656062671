import {
  Component
} from '@angular/core';
import {
  NgForm
} from '@angular/forms';
import {
  Router
} from '@angular/router';




import {
  checkSpecials,
  errorMessage
} from '../../constants/constants';
import {
  RegisterService
} from 'src/app/services/register.service';
import { emailValidator } from 'src/app/constants/utils/utils';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss']
})
export class SignupComponent {

  constructor(private router: Router, private register: RegisterService) {}
  errorMsg;
  isFnameError;
  isLnameError;
  isEmailError;
  isBusinessNameError;
  isAccountNumberError;
  isProcessing

  isFnameTouched = false
  isLnameTouched = false
  isEmailTouched = false
  isbusinessNameTouched = false
  isCustomerNumberTouched = false

  isInputAvailable = false
  
  inputValidation(inputName,event,value){

    this.isInputAvailable =true

    switch(inputName){
      case 'fname' : {
        if(value.toString().length < 3){
          this.errorMsg = errorMessage.firstName;
          this.isFnameError = true;
        }else{
          this.isFnameError = false;
          this.errorMsg='';
        }
        this.isFnameTouched = true
        break;
      }
      case 'lname' : {
        if(value.toString().length < 3){
          this.errorMsg = errorMessage.lastName;
          this.isLnameError = true;
        }else{
          this.isLnameError = false;
          this.errorMsg='';
        }
        this.isLnameTouched = true
        break;
      }
      case 'email' : {
        if (!emailValidator(value)) {
          this.errorMsg = errorMessage.email;
          this.isEmailError = true;
        }else{
          this.isEmailError = false;
          this.errorMsg='';
        }
        this.isEmailTouched = true
        break;
      }
      case 'BusinesName' :{
        if (value.toString().length < 3) {
          this.errorMsg = errorMessage.businessName;
          this.isBusinessNameError = true;
        } else{
          this.isBusinessNameError = false;
          this.errorMsg='';
        }
        this.isbusinessNameTouched = true
        break;
      }
      case 'acNumber' :{
        if (!(value.toString().length > 4 && value.toString().length < 11)) {
          this.errorMsg = errorMessage.accountNumber;
          this.isAccountNumberError = true;
        }else{
          this.isAccountNumberError = false;
          this.errorMsg='';
        }
        this.isCustomerNumberTouched = true
        break;
      }

    }

  }

  

  onSubmit(form: NgForm) {
  
    this.isProcessing=true
    this.errorMsg;

    if(!this.isInputAvailable){
      this.isProcessing=false
      this.errorMsg = errorMessage.allFieldRequired;
      return
    }

    if( !this.isFnameTouched || !this.isLnameTouched || !this.isEmailTouched || !this.isbusinessNameTouched || !this.isCustomerNumberTouched){
      this.isProcessing=false
      this.errorMsg = errorMessage.allFieldRequired;
      return
    }

    if(this.isBusinessNameError || this.isAccountNumberError || this.isEmailError || this.isLnameError || this.isFnameError){
      this.isProcessing=false
      this.errorMsg = errorMessage.allFieldRequired;
      return
    }
    
    let requestBody = {
      'user_firstName': form.value.fname.toString(),
      'user_lastName': form.value.lname.toString(),
      'user_email': form.value.email,
      'user_custName': form.value.BusinesName.toString(),
      'user_custNumber': form.value.acNumber
    }

    let body = {
      'dealerline_signup': {
        'userdetails': requestBody
      }
    }
  
    this.register.registerDealer(body).subscribe(res=>{
     
      if(res.statusCode==200){
        this.isProcessing=false;
        this.router.navigate(['success'])
      }
      if(res.statusCode!== 200){
        this.isProcessing=false;
        this.errorMsg = errorMessage.unknownError;
      }
    },err=>{
      this.errorMsg = errorMessage.unknownError;
      this.isProcessing=false;
    })

    
  }
}