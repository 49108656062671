import {
  NgModule
} from '@angular/core';
import {
  Routes,
  RouterModule
} from '@angular/router';
import {
  AppComponent
} from './app.component';
import {
  SignupComponent
} from './components/signup/signup.component';
import {
  ThankYouComponent
} from './components/thank-you/thank-you.component';

const routes: Routes = [{
    path: '',
    pathMatch: 'full',
    redirectTo: '/signup'
  },
  {
    path: 'signup',
    component: SignupComponent,
    data: {
      animation: 'SignUp'
    }
  },
  {
    path: 'success',
    component: ThankYouComponent,
    data: {
      animation: 'ThankYou'
    }
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}