export const emailValidateRegX = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/ ;
export const checkSpecials = /^[A-Za-z0-9 ]+$/ ;

export const errorMessage = {
    firstName: 'First name should contain atleast 3 characters',
    lastName: 'Last name should contain atleast 3 characters',
    email: 'Enter a valid email',
    businessName: 'Business Name should contain atleast 3 characters',
    accountNumber: 'Please provide a valid customer number',
    unknownError: 'Some unknown error occured!',
    allFieldRequired: 'All fields are required!'
}

export const apiEndpointUrl = 'https://u879dlcyg5.execute-api.us-west-2.amazonaws.com/dev/signup' //Updated by Abhishek because of new APIGW deployment
// export const apiEndpointUrl = 'https://cl7jcbrujk.execute-api.us-west-2.amazonaws.com/dev/signup'
