import {
  Injectable
} from '@angular/core';
import {
  HttpClient
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { apiEndpointUrl } from '../constants/constants';

@Injectable({
  providedIn: 'root'
})
export class RegisterService {

  constructor(private http: HttpClient) {}
  
  registerDealer(body): Observable<any> {
    return this.http.post(`${apiEndpointUrl}`, body);
  }
}